var can_ajax,
old_url, site_url = MyAjax.ajaxurl.replace("wp-admin/admin-ajax.php", "");

var ms_ie = false;
var ua = window.navigator.userAgent;
var old_ie = ua.indexOf('MSIE ');
var new_ie = ua.indexOf('Trident/');

if ((old_ie > -1) || (new_ie > -1)) {
ms_ie = true;
}
if (Modernizr.history && ms_ie !== true) { // ask Modernizr if the browser supports history
can_ajax = true;
} else {
can_ajax = false;
}
//////////////////////////////////////////////////

//can_ajax = false;


////////////////////

var xhr, current_url;
if ($(window).width() < 992) { // dont do it on iphone as the page zooms in
ms_ie = true; // lets start_navigation_away function still work but turns off ajax
}
if (can_ajax || ms_ie) {
var $main = $("#ajax_me_wrapper"),
    loadPage = function(href, type) {
        current_url = window.location.href;
        if (xhr && xhr.readystate !== 4) {
            xhr.abort(); // if user clicked twice on a link abort the first
        }
        xhr = $.ajax({
            type: 'GET',
            url: href,
            data: {
                //MY_REFERER: old_url, NEW_REFERER: href
            },
            /*                                beforeSend: function(xhr) {
                                                xhr.setRequestHeader("MY_REFERER", old_url);
                                            },*/
            success: function(response) {
                var split_html = response.split('<body class="');
                var updated_html = split_html[0] + '<body class="' + "updated_dom " + split_html[1];


                // REMOVE SCRIPTS THAT DUPLICATE ON AJAX - for scripts that persist past document overwrite.
                var remove_script = updated_html.split('<script');
                for (var index = 0; index < remove_script.length; index++) {   
                    if (remove_script[index].indexOf("</script>") > -1) {
                      if (remove_script[index].match( /(googletagmanager|GoogleAnalyticsObject)|fbq/ )) { // unique name of script. cant be general as it will conflict with other page elements named the same. separeate multipull entries bt pipes one|two|three ...
                        remove_script[index] = remove_script[index].substring(remove_script[index].indexOf("</script>") + 9);
                      } else { // dont break these scripts
                        remove_script[index] = "<script"+remove_script[index];
                      } 
                    }
                }
                //console.log(remove_script.join(""));
                updated_html = remove_script.join("");
                // END REMOVE SCRIPTS THAT DUPLICATE ON AJAX

                //scrape title
                var get_title = updated_html.split('<title>');
                var the_title = get_title[1].split('</title>')[0];     

                if (type !== "popstate") {
                    goToByScroll($("#the_page", document), 0, 1.0);
                    /* jshint ignore:start */
                    setTimeout(function() {
                        document.open();
                        document.write(updated_html);
                        document.close();
                    }, 1000);
                    /* jshint ignore:end */
                } else {
                    /* jshint ignore:start */
                    document.open();
                    document.write(updated_html);
                    document.close();
                    /* jshint ignore:end */
                }
                document.title = the_title; // analytics cant see the pushState title unless we set it here first
                history.pushState({}, the_title, href);  
                //console.log(the_title);
            },
            error: function(xhr) {
                if (xhr.statusText !== "abort") { // user just clicked the link twice and we have handled this so its not an error.
                    surgeSitePreloaderOff();
                    $("body").css("cursor", "auto");
                    //console.log(current_url);
                    history.replaceState({}, '', current_url);
                    swal({
                        title: "Warning",
                        text: "Sorry but there was an error: " + xhr.status + " " + xhr.statusText,
                        showConfirmButton: false,
                        type: "warning",
                        timer: 3800
                    });
                }
            }
        });
    };


/*
 * Necessary hack because WebKit fires a popstate event on document load
 * https://code.google.com/p/chromium/issues/detail?id=63040
 * https://bugs.webkit.org/process_bug.cgi
 */
window.addEventListener('load', function() {
    setTimeout(function() {
        window.addEventListener('popstate', function() {
            /*console.log(window.location);
            console.log(current_url);*/
            if (window.location.href !== current_url) { //if the popstate puts the href back to the original pages url then do nothing.
                surgeSitePreloaderOn();
                loadPage(location.href, "popstate");
            }
        });
    }, 0);
});
var start_navigation_away = function(el, url) {

    var get_params = "";

    if (url !== "") {
        if (window.location.href.indexOf(".dev:3000") > -1 && url.indexOf(":3000") === -1) {
            // if we are on local dev pc add :3000 if its not already part of the url
            url = url.replace(document.location.hostname, document.location.hostname + ":3000");
        }
        surgeSitePreloaderOn();
        $("body").css("cursor", "progress");
        //console.log(site_url);
        if (url.indexOf(site_url) > -1 || url.indexOf(':') === -1) {
            if (ms_ie) {
                window.location.href = url + get_params;
            } else {
                loadPage(url + get_params);
                //history.state = "navigate";
                //history.pushState({}, '', url + get_params);  // done in loadPage function now
            }
            return false;
        }
    } else {
        var href = $(el).attr("href");
        //console.log(href);

        //if (href !== "#") {
        if (!href.match("^#")) { // if href does not start with hash
            if (window.location.href.indexOf(".dev:3000") > -1 && href.indexOf(":3000") === -1) {
                // if we are on local dev pc and current url has :3000 add :3000 if its not already part of the links url
                href = href.replace(document.location.hostname, document.location.hostname + ":3000");
            }
            if (href.indexOf(site_url) === 0 || href.indexOf(':') === -1) {
                surgeSitePreloaderOn();
                $("body").css("cursor", "progress");
                if (ms_ie) {
                    window.location.href = href + get_params;
                } else {
                    loadPage(href + get_params);
                    //history.state = "navigate";
                    //history.pushState({}, '', href + get_params); // done in loadPage function now
                }
            } else {
                window.open(href + get_params, '_blank');
                surgeSitePreloaderOff();
                return false; 
            }
        }
    }
}; 
$(document).on("click", "a, area", function() {        
    // if ($(this).attr("target") !== "_blank") {        
    //   if ($(window).width() < 992 && $(this).is(".is_dropdown .parent_text a")) { 
    //     // stop top level links in the mega menu from navigating away if we are on mobile.
    //     return false;
    //   }
    //   if (this.href === window.location.href) {
    //       /*                    swal({
    //                               title: "That link leads to the same page as you're already on!",
    //                               type: "info",
    //                               timer: 3000,
    //                               showConfirmButton: false
    //                           });*/
    //       return false;
    //   }

    //   if (!$(this).closest("#wpadminbar").length && !$(this).hasClass("festi-cart-remove-product") && !$(this).hasClass("see_letterhead") && !$(this).parent().hasClass("tiled-gallery-item") && !$(this).hasClass("remove") && !$(this).hasClass("ajax_add_to_cart") && !$(this).parent().hasClass("gallery-icon") && !$(this).hasClass("download_button") && !$(this).parent().hasClass("download_button") && $(this).attr("href").indexOf('.pdf') === -1 && $(this).attr("href").indexOf('.zip') === -1 && $(this).attr("href").indexOf('.xls') === -1 && $(this).attr("href").indexOf('.docx') === -1) {
    //       old_url = window.location.href;
    //       start_navigation_away(this, "");
    //       return false;
    //   }
    // }
    if ($(this).attr("href") === "#") { 
      return false;
    } 
});
}
